<template>
   <v-card>
    <v-toolbar
          color="#4ECDC4"
          dark
          flat
    >   
      <span class="text-h6 mr-4" >3Dモデルの表示 </span>
      <v-spacer></v-spacer>
      <v-btn 
        icon
        @click="close"
      > 
        <v-icon>{{icons.close}}</v-icon>
      </v-btn>
    </v-toolbar> 

    <model-viewer
      id="viewer"
      :src="modelUrl"
      alt="A 3D model"
      camera-controls
      ar
    ></model-viewer>
  </v-card>
</template>

<script>
import { mdiClose } from '@mdi/js';
import ('@google/model-viewer');

export default {
  name: 'Dmodel',
  props:["modelUrl","loading"],
  data: () => ({
    icons:{
      "close":mdiClose
    },
  }),
  watch:{
   modelUrl: function(){
     this.setSize();
   },
  },
 
  methods: {
    close(){
      this.$emit('closeEmit')
    },

    setSize(){
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight-65;
      document.getElementById("viewer").style=`width:${width}px;height:${height}px;`
      
    }

  }
}
</script>

  <style>
    model-viewer {
      background-color:rgb(101, 233, 224);;
    }
  </style>